export function decodeJwt(token, fromServer = true) {
  if (!token) {
    return;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  if (!fromServer) {
    return JSON.parse(window.atob(base64));
  } else {
    return JSON.parse(Buffer.from(base64, 'base64').toString());
  }
}

export const countDownHandler = (toDate, fromDate) => {
  let timeLeft = { isCountDown: false };

  if (fromDate && new Date() <= new Date(fromDate)) {
    return timeLeft;
  }

  const difference = new Date(toDate) - new Date();

  if (difference > 0) {
    timeLeft = {
      isCountDown: true,
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

export const preventLettersInInput = (event) => {
  const input = event.key;
  // Allow control keys (e.g., backspace, delete)
  if (
    event.ctrlKey ||
    event.altKey ||
    event.metaKey ||
    input === 'Backspace' ||
    input === 'Delete'
  ) {
    return;
  }

  // Check if the input is not a number
  if (isNaN(Number(input))) {
    event.preventDefault();
  }
};

export function compareObjects(obj1, obj2) {
  // Get the keys of the objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Iterate over the keys and compare the values
  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  // If all keys and values are equal, return true
  return true;
}

export function findObjectByIdV2(data, targetKey, searchValue) {
  if (data?.length) {
    for (const item of data) {
      if (item[targetKey] == searchValue) {
        // prettyConole(searchValue, item)
        return item;
      }

      if (item?.children_data && item?.children_data.length > 0) {
        const result = findObjectByIdV2(
          item?.children_data,
          targetKey,
          searchValue
        );
        if (result) {
          // prettyConole(searchValue, result)
          return result;
        }
      }
    }
  }
  // Target object not found
  return null;
}

// Normalize the phone number to always start with 05
export function normalizeMobileNumber(number) {
  // Remove any leading or trailing whitespace
  let normalized = number?.trim();

  // Check if the number starts with '966', '+966', or '009966' and remove these prefixes
  if (normalized.startsWith('966')) {
    normalized = normalized.substring(3)?.startsWith('0') ? '0' + normalized.substring(4) : '0' + normalized.substring(3); // Remove 966 and add leading zero
  } else if (normalized.startsWith('+966')) {
    normalized = normalized.substring(4)?.startsWith('0') ? '0' + normalized.substring(5) : '0' + normalized.substring(4); // Remove +966 and add leading zero
  } else if (normalized.startsWith('009966')) {
    normalized = normalized.substring(5)?.startsWith('0') ? '0' + normalized.substring(6) : '0' + normalized.substring(5); // Remove 009966 and add leading zero
  } else if (normalized.startsWith('0')) {
    // If already starts with 0, leave it as is
    normalized = '0' + normalized.substring(1); // Ensure it starts with 0 if it's missing
  }

  return normalized;
}

// Denormalize the phone number to international format (e.g., +966)
export function denormalizeMobileNumber(number) {
  // Remove any leading or trailing whitespace
  let denormalized = number?.trim();

  // Check if it starts with '0' and replace it with '+966'
  if (denormalized.startsWith('0')) {
    denormalized = '+966' + denormalized.substring(1); // Replace the leading 0 with +966
  }

  return denormalized;
}

export function stripHTML(input) {
  let decodedValue = decodeURIComponent(input);
  return decodedValue.replace(/<\/?[^>]+(>|$)/g, ""); // Removes HTML tags
}

export const getDevicesKeys = (isMobile) => {
  const active = isMobile ? 'mobile-active' : 'active';
  const position = isMobile ? 'mobile-position' : 'position';
  return { active, position };
};